import { useEffect, useState } from "react";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { useNavigate } from 'react-router-dom';

import 'bulma/css/bulma.min.css';
import "bulma-responsive-tables/css/main.min.css";

import { MmmbAPI } from "../../api/mmmb";
import {IngredientDetail} from "../../components/IngredientDetail/IngredientDetail";
import { addIngredientInputText, setIngredientList } from "store/recipecalculation/recipecalculation-slice";
import { useDispatch, useSelector } from "react-redux";
import {IngredientList} from "../../components/IngredientList/IngredientList";
import {NutriFactList} from "../../components/NutriFactList/NutriFactList";
import {IngredientChartBar} from "../../components/IngredientChartBar/IngredientChartBar";
import {RecipeTotalWeight} from "../../components/RecipeTotalWeight/RecipeTotalWeight";

import s from "./style.module.css";
// MmmbAPI.fetchRecipe();

import { useParams } from 'react-router-dom';

{/*
comment
 */}



export function RecipeCalculation() {


  // Ce hook permet d'appeller l'événement Redux correspondant à l'action du slice.
  // Un composant qui utilise dispatch est dit "composant connecté" car il dépend de Redux.
  // Ce genre de composants se met dans un dossier src/containers normalement pour séparer les composants indépendants et ceux dépendant du store.
  // Les composants indépendants étant plus réutilisables on veut les séparer. ici c'est mort j'ai mis dans App.
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Déclarez un état pour stocker la valeur du textarea
  const [textareaValue, setTextareaValue] = useState("");
  const [recipe_dict, set_recipe_dict] = useState('');
  const [error_msg, set_error_msg] = useState('');
  const [save_success, set_save_success] = useState(false);


  // const ingredientList = useSelector((store) => store.RECIPECALCULATION.ingredients);
  const recipeDict = useSelector((store) => store.RECIPECALCULATION);


  const { recipe_id } = useParams();

  const loadRecipeDict = async () => {
    try {
      const recipe_dict = await MmmbAPI.getRecipe(recipe_id);
      if ("error" in recipe_dict) {
        set_error_msg(recipe_dict['error']);
      } else {
        dispatch(setIngredientList({'recipe_dict': recipe_dict}));
      }
    } catch (error) {
      set_error_msg("An error occurred while loading the recipe.");
    }
  };

  useEffect(() => {
    if (recipe_id) {
      loadRecipeDict(); // Call the loadRecipeDict function here
    }
  }, []);


  const updateRecipeDict = async () => {
    const recipe_dict = await MmmbAPI.fetchRecipe(recipeDict);
    dispatch(setIngredientList({'recipe_dict': recipe_dict}));
  };

  const saveRecipeDict = async () => {
    const recipe_id = await MmmbAPI.saveRecipe(recipeDict);
    navigate(`/recipe/${recipe_id}`, { replace: true });
    set_save_success(true);
  };



  useEffect(() => {
     (async () => {

        // On appelle un action du Slice de Redux pour enregistrer le textarea
        dispatch(addIngredientInputText({'textareaValue': textareaValue}));

        if (textareaValue) {
          set_error_msg("");
          const recipe_dict = await MmmbAPI.fetchRecipeTextarea(textareaValue);
          if ("error" in recipe_dict) {
            set_error_msg(recipe_dict['error']);
          }
          dispatch(setIngredientList({'recipe_dict': recipe_dict}));
        }

        return () => {
           // Code exécuté uniquement si le composant disparait.
        };

  })();

  }, [textareaValue])


ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);



  // Gestionnaire d'événement pour mettre à jour la valeur du textarea et declencher appel HTTP
  const handleTextareaChange = (event) => {
    setTextareaValue(document.querySelector("#recipe_textarea").value);

  };

  const [activeTab, setActiveTab] = useState('tab1');

  return (


    <div className="container">

        <div>
          <div className="tabs is-toggle is-fullwidth">
            <ul>
              <li className={activeTab === 'tab1' ? 'is-active' : ''}>
                <a onClick={() => setActiveTab('tab1')}>
                  <div>
                    {/* mobile display */}
                    <span className="is-hidden-desktop">
                      <i className="las la-utensils"></i>
                    </span>
                    {/* desktop display */}
                    <span className="is-hidden-mobile">
                      &nbsp;Ingrédients
                    </span>
                  </div>
                </a>
              </li>
              <li className={activeTab === 'tab4' ? 'is-active' : ''}>
                <a onClick={() => setActiveTab('tab4')}>
                  <div>
                    {/* mobile display */}
                    <span className="is-hidden-desktop">
                      <i className="las la-table"></i>
                    </span>
                    {/* desktop display */}
                    <span className="is-hidden-mobile">
                      &nbsp;Valeurs n.
                    </span>
                  </div>
                </a>
              </li>

              <li className={activeTab === 'tab2' ? 'is-active' : ''}>
                <a onClick={() => setActiveTab('tab2')}>
                  {/* mobile display */}
                  <span className="is-hidden-desktop">
                    <i className="las la-chart-bar"></i>
                  </span>
                  {/* desktop display */}
                  <span className="is-hidden-mobile">
                    &nbsp;Graphiques
                  </span>
                </a>
              </li>
              <li className={activeTab === 'tab3' ? 'is-active' : ''}>
                <a onClick={() => setActiveTab('tab3')}>
                <span className="is-hidden-desktop">
                  <i className="las la-cog"></i>
                </span>
                <span className="is-hidden-mobile">
                  &nbsp;Configuration
                </span>
                </a>
              </li>
            </ul>
          </div>

          <div>
            {activeTab === 'tab1' && <div>

                <div className="input-section">
                {/*    ICI   */}
                    <textarea id="recipe_textarea" className={s.recipetextarea} placeholder="Ajoutez les ingrédients de la recette..." />

                    <button className="button is-success is-fullwidth" onClick={handleTextareaChange}>Valider</button>
                    <RecipeTotalWeight recipeDict={recipeDict} updateRecipeDict={updateRecipeDict} />

                    {error_msg &&
                      <div className="container">
                        <div className="notification is-danger">
                          <div className="is-flex is-align-items-center">
                            <span className="icon">
                              <i className="las la-lg la-exclamation-circle"></i>
                            </span>
                            <strong>Erreur :&nbsp;</strong>{ error_msg }
                          </div>
                        </div>
                      </div>
                    }
                </div>
                <div className="main-nutrition-table">
                    <IngredientList recipeDict={recipeDict} updateRecipeDict={updateRecipeDict}/>
                </div>


            </div>}


            {activeTab === 'tab2' && <div>

                <h2 className="title">Participation aux Kcal pour 100g</h2>
                <IngredientChartBar recipeDict={recipeDict} height="220px" nutritionalFactList={["Energie, N x facteur Jones, avec fibres  (kcal/100 g)"]}/>
                <h2 className="title">Participation aux valeurs nutritonnelles principales pour 100g</h2>
                <IngredientChartBar recipeDict={recipeDict} nutritionalFactList={[
                  "Protéines, N x facteur de Jones (g/100 g)",
                  "Glucides (g/100 g)",
                  "Lipides (g/100 g)"
                  ]}/>

                <h2 className="title">Participation aux vitamines pour 100g</h2>
                <IngredientChartBar recipeDict={recipeDict} height="370px" nutritionalFactList={[
                  "Vitamine D (µg/100 g)",
                  "Vitamine E (mg/100 g)",
                  "Vitamine K1 (µg/100 g)",
                  "Vitamine K2 (µg/100 g)",
                  "Vitamine C (mg/100 g)",
                  "Vitamine B1 ou Thiamine (mg/100 g)",
                  "Vitamine B2 ou Riboflavine (mg/100 g)",
                  "Vitamine B3 ou PP ou Niacine (mg/100 g)",
                  "Vitamine B5 ou Acide pantothénique (mg/100 g)",
                  "Vitamine B6 (mg/100 g)",
                  "Vitamine B9 ou Folates totaux (µg/100 g)",
                  "Vitamine B12 (µg/100 g)",
                  ]}/>

                <h2 className="title">Participation aux minéraux pour 100g</h2>
                <IngredientChartBar recipeDict={recipeDict} height="330px" nutritionalFactList={[
                  "Calcium (mg/100 g)",
                  "Cuivre (mg/100 g)",
                  "Fer (mg/100 g)",
                  "Iode (µg/100 g)",
                  "Magnésium (mg/100 g)",
                  "Manganèse (mg/100 g)",
                  "Phosphore (mg/100 g)",
                  "Potassium (mg/100 g)",
                  "Sélénium (µg/100 g)",
                  "Zinc (mg/100 g)",
                  ]}/>

                <h2 className="title">Participation à d'autres valeurs pour 100g</h2>
                <IngredientChartBar recipeDict={recipeDict} height="400px" nutritionalFactList={[
                  "Sucres (g/100 g)",
                  "Fructose (g/100 g)",
                  "Glucose (g/100 g)",
                  "Saccharose (g/100 g)",
                  "Lactose (g/100 g)",
                  "Fibres alimentaires (g/100 g)",
                  "Polyols totaux (g/100 g)",
                  "Cendres (g/100 g)",
                  "AG saturés (g/100 g)",
                  "AG monoinsaturés (g/100 g)",
                  "AG polyinsaturés (g/100 g)",
                  "Cholestérol (mg/100 g)",
                  "Sel chlorure de sodium (g/100 g)",
                  ]}/>





            </div>}
            {activeTab === 'tab3' && <div>

            <button className="button" onClick={(e) => saveRecipeDict(e)}>Sauvegarder</button>
            {save_success &&
              <div className="container">
                <div className="notification is-success">
                  <div className="is-flex is-align-items-center">
                    <span className="icon">
                      <i className="las la-cloud"></i>
                    </span>
                    Recette enregistrée. N'oubliez pas de sauvegarder votre URL.
                  </div>
                </div>
              </div>
            }

            </div>}
            {activeTab === 'tab4' && <div>

              <NutriFactList recipeDict={recipeDict} updateRecipeDict={updateRecipeDict}/>

            </div>}


          </div>
        </div>


    </div>




  );
}
