import React, { useEffect, useState, useRef } from 'react';
import s from "./style.module.css";

export function NumberSignificant({ number, digits }) {

    const formatNumberWithSignificantDigits = (number, digits) => {
        if (digits <= 0) return number.toString();
        return Number(number).toPrecision(digits);
    };

    const formattedNumber = formatNumberWithSignificantDigits(number, digits);

    return (
        <span>
            {formattedNumber}
        </span>
    );

}