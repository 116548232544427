import s from "./style.module.css";
import { NutriFactItem } from "../IngredientItem/IngredientItem";
import { NumberSignificant } from "../NumberSignificant/NumberSignificant";



export function NutriFactList({ recipeDict, updateRecipeDict }) {
  return (
    <div className="container">
      <h2 className="title">Valeurs nutritionnelles pour 100g</h2>

      <div className="">
        <table className="table is-fullwidth is-striped is-hoverable has-sticky-header has-sticky-footer has-sticky-column">
          <thead>
            <tr>
              <th>Valeur nutritionnelle</th>
              <th>Valeur</th>
            </tr>
          </thead>
          <tbody>


            {recipeDict.recipe_calculation && recipeDict.recipe_calculation.nutritional_fact_100g && (
              Object.entries(recipeDict.recipe_calculation.nutritional_fact_100g).map(([nutri_fact_name, nutri_fact_value], index) => (
                <tr key={index}>
                  <td><strong>{nutri_fact_name}</strong></td>
                  <td>
                    <NumberSignificant number={nutri_fact_value} digits={3} />
                  </td>
                </tr>
              ))
            )}


          </tbody>
        </table>
      </div>
    </div>
  );
}
