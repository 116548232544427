import s from "./style.module.css";

import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Colors } from 'chart.js';

export function IngredientChartBar({ recipeDict, nutritionalFactList=[], height = '300px', width = '80%' }) {

function generatePastelColors(nbColors) {
    const colors = [];
    let previousHue = null;
    const hueStep = 360 / nbColors; // Écart uniforme entre les teintes

    for (let i = 0; i < nbColors; i++) {
        let hue;

        // Si la teinte précédente existe, ajouter une distance significative
        if (previousHue !== null) {
            hue = (previousHue + hueStep + Math.floor(Math.random() * 30 - 15)) % 360; // Écart de ±15 pour plus de variation
        } else {
            hue = Math.floor(Math.random() * 360); // Première couleur complètement aléatoire
        }

        // Pour éviter les couleurs vives, nous contrôlons la saturation et la luminosité
        const isPastel = Math.random() > 0.5; // Choisir aléatoirement entre pastel ou une autre nuance douce

        let saturation, lightness;

        if (isPastel) {
            // Couleurs pastel : saturation faible, luminosité élevée
            saturation = 60 + Math.floor(Math.random() * 10); // Saturation autour de 60-70%
            lightness = 80 + Math.floor(Math.random() * 10);  // Luminosité autour de 80-90%
        } else {
            // Autres nuances douces : saturation et luminosité contrôlées
            saturation = 40 + Math.floor(Math.random() * 20); // Saturation entre 40-60%
            lightness = 60 + Math.floor(Math.random() * 20);  // Luminosité entre 60-80%
        }

        colors.push(`hsl(${hue}, ${saturation}%, ${lightness}%)`);
        previousHue = hue;
    }

    return colors;
}

if (!nutritionalFactList.length) {
  nutritionalFactList = [
    "Energie, N x facteur Jones, avec fibres  (kcal/100 g)",
    "Protéines, N x facteur de Jones (g/100 g)",
    "Glucides (g/100 g)",
    "Lipides (g/100 g)"
  ];
}


ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);


const chartData = {
  labels: [],
  datasets: []
};

// To get /100g values
let rate_100g = null;
try {
  rate_100g = recipeDict.recipe_calculation.summary.weight/100;
}
catch {

}


recipeDict.ingredients.forEach(ingredient => {
  const label = ingredient.ciqual_ingredient;
  const data = [];
  for (let key in ingredient.nutritional_fact) {
    if (!nutritionalFactList.includes(key)) {
      continue;
    }
    data.push(ingredient.nutritional_fact[key]*(ingredient.qty/100)/rate_100g);
    if (!chartData.labels.includes(key)) {
      chartData.labels.push(key);
   }
  }
  chartData.datasets.push({
    label: label,
    data: data,
    backgroundColor: generatePastelColors(data.length),
  });
});


const options = {
  indexAxis: 'y',
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Nutritional Values of Foods (Horizontal Stacked Bar Chart)',
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};


  return (
    <div className="container" style={{ height: height }}>

      <Bar data={chartData} options={options} />

    </div>
  );
}
