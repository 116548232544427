import s from "./style.module.css";
import { IngredientItem } from "../IngredientItem/IngredientItem";
import { RateSpan } from "../RateSpan/RateSpan";

export function IngredientList({ recipeDict, updateRecipeDict }) {
  return (
    <div className="container">
      <h1 className="title">Liste des Ingrédients</h1>

      <div className="">
        <table className="table is-fullwidth is-striped is-hoverable has-sticky-header has-sticky-footer has-sticky-column">
          <thead>
            <tr>
              <th>#</th>
              <th>Nom</th>
              <th>Modifier</th>
              <th>Poids</th>
              <th>Kcal</th>
              <th>Proteine</th>
              <th>Lipides</th>
              <th>Glucides</th>
            </tr>
          </thead>
          <tbody>
            {recipeDict.recipe_calculation && recipeDict.recipe_calculation.summary && (
              <tr className="total_recipe">
                <td></td>
                <td data-label="id"><strong>Total</strong></td>
                <td></td>
                <td data-label="ingredient_poids">
                  <RateSpan value={recipeDict.recipe_calculation.summary.effective_recipe_weight} rate={0.3} />g
                  <br />Total ingrédients: <span className={s.weightAllIngredients} >{recipeDict.recipe_calculation.summary.weight} g</span>
                </td>
                <td data-label="ingredient_kcal">
                  <RateSpan value={recipeDict.recipe_calculation.nutritional_fact['Energie, N x facteur Jones, avec fibres  (kcal/100 g)']} rate={0.3} />

                </td>
                <td data-label="ingredient_proteine">
                  <RateSpan value={recipeDict.recipe_calculation.nutritional_fact['Protéines, N x 6.25 (g/100 g)']} rate={0.3} />g

                </td>
                <td data-label="ingredient_lipide">
                  <RateSpan value={recipeDict.recipe_calculation.nutritional_fact['Lipides (g/100 g)']} rate={0.3} />g

                </td>
                <td data-label="ingredient_glucides">
                  <RateSpan value={recipeDict.recipe_calculation.nutritional_fact['Glucides (g/100 g)']} rate={0.3} />g
                </td>
              </tr>

            )}

            {recipeDict.recipe_calculation && recipeDict.recipe_calculation.summary && (
              <tr className="total_recipe" style={{"fontWeight": '700', size: "2em", "backgroundColor":"#dddddd"}}>
                <td></td>
                <td data-label="id"><strong>Pour 100 g</strong></td>
                <td></td>
                <td data-label="ingredient_poids">100 g</td>
                <td data-label="ingredient_kcal">
                  <RateSpan value={(recipeDict.recipe_calculation.nutritional_fact_100g['Energie, N x facteur Jones, avec fibres  (kcal/100 g)'])} rate={0.3} />

                </td>
                <td data-label="ingredient_proteine">
                  <RateSpan value={(recipeDict.recipe_calculation.nutritional_fact_100g['Protéines, N x 6.25 (g/100 g)'])} rate={0.3} />g

                </td>
                <td data-label="ingredient_lipide">
                  <RateSpan value={(recipeDict.recipe_calculation.nutritional_fact_100g['Lipides (g/100 g)'])} rate={0.3} />g

                </td>
                <td data-label="ingredient_glucides">
                  <RateSpan value={(recipeDict.recipe_calculation.nutritional_fact_100g['Glucides (g/100 g)'])} rate={0.3} />g
                </td>
              </tr>
            )}

            {recipeDict.ingredients.map((ingredient, i) => (
              <IngredientItem
                key={ingredient.index}
                ingredient={ingredient}
                updateRecipeDict={updateRecipeDict}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
